var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "el-radio-group",
        {
          attrs: { disabled: _vm.disabled },
          on: {
            change: function ($event) {
              ;(_vm.ruleForm.returnCouponRelationId = ""),
                (_vm.ruleForm.returnCouponRelationName = ""),
                (_vm.ruleForm.returnCouponPackageId = ""),
                (_vm.ruleForm.returnCouponPackageName = ""),
                (_vm.options = [])
            },
          },
          model: {
            value: _vm.ruleForm.returnCouponRelationType,
            callback: function ($$v) {
              _vm.$set(_vm.ruleForm, "returnCouponRelationType", $$v)
            },
            expression: "ruleForm.returnCouponRelationType",
          },
        },
        [
          _c("el-radio", { attrs: { label: 2 } }, [_vm._v(" 优惠券 ")]),
          _vm.showCouponBack
            ? _c("el-radio", { attrs: { label: 1 } }, [_vm._v(" 券包 ")])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _vm.ruleForm.returnCouponRelationType === 2
            ? _c(
                "el-select",
                {
                  staticStyle: { width: "400px" },
                  attrs: {
                    filterable: "",
                    remote: "",
                    clearable: "",
                    placeholder: "请输入筛选券id、名称关键词",
                    "remote-method": _vm.remoteMethod,
                    loading: _vm.loading,
                    size: "small",
                    disabled: _vm.disabled,
                  },
                  on: { change: _vm.changeSelect },
                  model: {
                    value: _vm.ruleForm.returnCouponRelationId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "returnCouponRelationId", $$v)
                    },
                    expression: "ruleForm.returnCouponRelationId",
                  },
                },
                _vm._l(_vm.options, function (item, i) {
                  return _c("el-option", {
                    key: i,
                    attrs: { label: item.couponName, value: item.couponId },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm.ruleForm.returnCouponRelationType === 1
            ? _c(
                "el-select",
                {
                  staticStyle: { width: "400px" },
                  attrs: {
                    filterable: "",
                    remote: "",
                    clearable: "",
                    placeholder: "请输入筛选券id、名称关键词",
                    "remote-method": _vm.remoteMethod,
                    loading: _vm.loading,
                    size: "small",
                  },
                  on: { change: _vm.changeSelect },
                  model: {
                    value: _vm.ruleForm.returnCouponPackageId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "returnCouponPackageId", $$v)
                    },
                    expression: "ruleForm.returnCouponPackageId",
                  },
                },
                _vm._l(_vm.options, function (item, i) {
                  return _c("el-option", {
                    key: i,
                    attrs: {
                      label: item.couponPackageName,
                      value: item.couponPackageId,
                    },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }