<template>
  <div class="">
    <el-button
      v-if="!disabled"
      type="text"
      @click="showMainGoods"
    >
      选择商品
    </el-button>

    <el-table
      border
      style="width: 100%"
      :data="list.filter(item => item.isDelete !== 1)"
      size="small"
      :header-cell-style="{
        background: '#d9dde1',
        color: '#0d0202',
      }"
    >
      <el-table-column
        align="center"
        prop="goodsId"
        label="商品ID"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="goodsName"
        label="商品名称"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="skuName"
        label="规格"
      >
      </el-table-column>
      <el-table-column
        align="center"
        label="库存"
      >
        <template slot-scope="{row}">
          {{ row.stock || row.stockQuantity }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="sellPrice"
        label="售价"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="date"
        label="操作"
      >
        <template slot-scope="{ row, $index }">
          <el-button
            type="text"
            :disabled="disabled"
            @click="removeSelectGood(row, $index)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      class="good-modal"
      title="选择商品"
      :visible.sync="showGoodsModal"
      width="900px"
      @open="searchClick"
    >
      <div>
        <div class="nav">
          <el-input
            v-model="goodsName"
            placeholder="请输入商品名称"
            size="mini"
            style="width: 220px"
          >
            <el-button
              slot="append"
              type="primary"
              size="mini"
              icon="el-icon-search"
              @click="searchClick"
            ></el-button>
          </el-input>
        </div>
        <div class="box">
          <el-table
            v-loading="loading"
            :data="allList"
            height="450"
            border
            style="width: 100%"
            size="small"
            :header-cell-style="{
              background: '#d9dde1',
              color: '#0d0202',
            }"
          >
            <el-table-column
              align="center"
              prop="id"
              label="图片"
            >
              <template slot-scope="{ row }">
                <img
                  style="width: 100px"
                  class="good-img"
                  :src="row.image"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="goodsName"
              label="商品名称"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="skuName"
              label="规格"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="sellPrice"
              label="价格"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="stockQuantity"
              label="库存"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="id"
              label="操作"
            >
              <template slot-scope="{ row }">
                <el-button
                  :disabled="row.checked"
                  type="text"
                  @click="selectGood(row)"
                >
                  {{ row.checked ? '已选择' : '选择' }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="page.total"
            :page-size="page.pageSize"
            :current-page="page.page"
            @current-change="changePage"
            @size-change="changePageSize"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      showGoodsModal: false,
      allList: [],
      goodsName: '',
      page: {
        page: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {

  },
  methods: {
    removeSelectGood(row, i) {
      this.$confirm('是否确认移除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$set(row, 'isDelete', 1);
      }).catch(() => {
      });
    },
    selectGood(row) {
      // this.$emit('update:list', [...this.list, row]);
      this.list.push({
        ...row, isDelete: 0
      });
      this.$set(row, 'checked', true);
    },
    showMainGoods() {
      this.showGoodsModal = true;
    },
    searchClick() {
      this.page.page = 1;
      this.getList();
    },
    changePage(p) {
      this.page.page = p;
      this.getList();
    },
    changePageSize(s) {
      this.page.page = 1;
      this.page.pageSize = s;
      this.getList();
    },
    getList() {
      this.loading = true;
      const pars = {
        supplierId: JSON.parse(localStorage.userInfo || '{}').suppliersId,
        goodsName: this.goodsName,
        currentPage: this.page.page,
        pageSize: this.page.pageSize
      };
      this.$axios.post(this.$api.exchangeOrder.skuGoodList, pars).then(r => {
        this.loading = false;
        if (r.code === 0) {
          this.page.total = Number(r.data.total);

          this.allList = r.data.records.map(item => {
            let find = this.list.find(find => Number(find.goodsId) === Number(item.goodsId));
            return {
              ...item,
              checked: (find && find.isDelete !== 1)
            }
          });
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
