var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      !_vm.disabled
        ? _c(
            "el-button",
            { attrs: { type: "text" }, on: { click: _vm.showMainGoods } },
            [_vm._v(" 选择商品 ")]
          )
        : _vm._e(),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            border: "",
            data: _vm.list.filter((item) => item.isDelete !== 1),
            size: "small",
            "header-cell-style": {
              background: "#d9dde1",
              color: "#0d0202",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", prop: "goodsId", label: "商品ID" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "goodsName", label: "商品名称" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "skuName", label: "规格" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "库存" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(" " + _vm._s(row.stock || row.stockQuantity) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "sellPrice", label: "售价" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "date", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row, $index }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", disabled: _vm.disabled },
                        on: {
                          click: function ($event) {
                            return _vm.removeSelectGood(row, $index)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "good-modal",
          attrs: {
            title: "选择商品",
            visible: _vm.showGoodsModal,
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showGoodsModal = $event
            },
            open: _vm.searchClick,
          },
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "nav" },
              [
                _c(
                  "el-input",
                  {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "请输入商品名称", size: "mini" },
                    model: {
                      value: _vm.goodsName,
                      callback: function ($$v) {
                        _vm.goodsName = $$v
                      },
                      expression: "goodsName",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: {
                        slot: "append",
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-search",
                      },
                      on: { click: _vm.searchClick },
                      slot: "append",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.allList,
                      height: "450",
                      border: "",
                      size: "small",
                      "header-cell-style": {
                        background: "#d9dde1",
                        color: "#0d0202",
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { align: "center", prop: "id", label: "图片" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("img", {
                                staticClass: "good-img",
                                staticStyle: { width: "100px" },
                                attrs: { src: row.image, alt: "" },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "goodsName",
                        label: "商品名称",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "skuName",
                        label: "规格",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "sellPrice",
                        label: "价格",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        align: "center",
                        prop: "stockQuantity",
                        label: "库存",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { align: "center", prop: "id", label: "操作" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: row.checked,
                                    type: "text",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectGood(row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.checked ? "已选择" : "选择") +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("Pagination", {
                  attrs: {
                    total: _vm.page.total,
                    "page-size": _vm.page.pageSize,
                    "current-page": _vm.page.page,
                  },
                  on: {
                    "current-change": _vm.changePage,
                    "size-change": _vm.changePageSize,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }