<template>
  <div class="">
    <el-radio-group
      v-model="ruleForm.returnCouponRelationType"
      :disabled="disabled"
      @change="
        ruleForm.returnCouponRelationId = '',
        ruleForm.returnCouponRelationName = '',
        ruleForm.returnCouponPackageId = '',
        ruleForm.returnCouponPackageName = '',
        options = []"
    >
      <el-radio :label="2">
        优惠券
      </el-radio>
      <el-radio
        v-if="showCouponBack"
        :label="1"
      >
        券包
      </el-radio>
    </el-radio-group>

    <div v-loading="loading">
      <el-select
        v-if="ruleForm.returnCouponRelationType === 2"
        v-model="ruleForm.returnCouponRelationId"
        style="width: 400px"
        filterable
        remote
        clearable
        placeholder="请输入筛选券id、名称关键词"
        :remote-method="remoteMethod"
        :loading="loading"
        size="small"
        :disabled="disabled"
        @change="changeSelect"
      >
        <el-option
          v-for="(item,i) in options"
          :key="i"
          :label="item.couponName"
          :value="item.couponId"
        >
        </el-option>
      </el-select>
      <!-- 券包 -->
      <el-select
        v-if="ruleForm.returnCouponRelationType === 1"
        v-model="ruleForm.returnCouponPackageId"
        style="width: 400px"
        filterable
        remote
        clearable
        placeholder="请输入筛选券id、名称关键词"
        :remote-method="remoteMethod"
        :loading="loading"
        size="small"
        @change="changeSelect"
      >
        <el-option
          v-for="(item,i) in options"
          :key="i"
          :label="item.couponPackageName"
          :value="item.couponPackageId"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    ruleForm: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: [],
      loading: false,
      showCouponBack: false,
      storeId: ''
    }
  },
  computed: {},
  watch: {
    'ruleForm.returnCouponRelationType': {
      handler(val) {
        if (val === 2 && this.ruleForm.returnCouponRelationId) { // 优惠券
          this.remoteMethod(this.ruleForm.returnCouponRelationId)
        } else if (val === 1 && this.ruleForm.returnCouponPackageId) {
          this.remoteMethod(this.ruleForm.returnCouponPackageId)
        }
      },
      immediate: false
    }
  },
  created() {},
  mounted() {
    this.storeId = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo')).storeList && JSON.parse(localStorage.getItem('userInfo')).storeList[0] ? JSON.parse(localStorage.getItem('userInfo')).storeList[0].storeId : localStorage.getItem('defaultStoreId');
    this.getUser();
    // 回填筛选
    // if (this.ruleForm.returnCouponRelationType === 2) {
    //   this.remoteMethod(this.ruleForm.returnCouponRelationId)
    // } else if (this.ruleForm.returnCouponRelationType === 1) {
    //   this.remoteMethod(this.ruleForm.returnCouponPackageId)
    // }
  },
  methods: {
    getUser() {
      this.$axios.get(this.$api.returnCoupon.package_supplier, {
        params: {
          supplierId: JSON.parse(localStorage.userInfo || '{}').suppliersId
        }
      }).then(res => {
        if (res.code === 0) {
          this.showCouponBack = res.data;
        }
      })
    },
    changeSelect(e) {
      if (this.ruleForm.returnCouponRelationType === 2) {
        let find = this.options.find(item => item.couponId === e);
        this.ruleForm.returnCouponRelationName = find.couponName;
        this.ruleForm.returnCouponPackageId = null;
        this.ruleForm.returnCouponPackageName = null;
      } else {
        let find = this.options.find(item => item.couponPackageId === e);
        this.ruleForm.returnCouponPackageName = find.couponPackageName;
        this.ruleForm.returnCouponRelationId = find.couponId;
        this.ruleForm.returnCouponRelationName = find.couponName;
      }
    },
    async remoteMethod(query) {
      if (query !== '') {
        let list = await this.getCouponList(this.ruleForm.returnCouponRelationType, query);
        this.options = list;
      } else {
        this.options = [];
      }
    },
    // type:1券包
    getCouponList(type, val) {
      this.loading = true;
      return new Promise(resolve => {
        this.$axios({
          method: 'post',
          url: this.$api.coupon.search_return_coupon,
          data: {
            couponName: val,
            couponType: type,
            currentPage: 1,
            pageSize: 200,
            isValid: 1,
            storeId: this.showCouponBack ? undefined : this.storeId
          }
        })
          .then((res) => {
            this.loading = false;
            if (res.code === 0) {
              resolve(res.data.records);
            }
          })
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
