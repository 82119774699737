<!-- /nb/marketingTool/shareGift/detail -->
<template>
  <div class="app-container">
    <div class="outer">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="110px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="活动名称："
          prop="returnCouponName"
        >
          <el-input
            v-model="ruleForm.returnCouponName"
            style="width: 400px"
            size="small"
            maxlength="30"
            show-word-limit
            placeholder="请输入活动名称"
            :disabled="type === 'show'"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="活动时间："
          prop="activeTimeList"
        >
          <el-date-picker
            v-model="ruleForm.activeTimeList"
            style="width: 400px"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
            value-format="timestamp"
            :disabled="type === 'show'"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item
          label="参与条件："
          required
        >
          <el-radio-group
            v-model="ruleForm.joinType"
            :disabled="type === 'show'"
          >
            <el-radio :label="1">
              购买指定商品
            </el-radio>
          </el-radio-group>

          <SelectGoodsSku
            :list="ruleForm.goodsList"
            :disabled="type === 'show'"
          />
        </el-form-item>

        <el-form-item
          label="返券："
          prop="returnCouponRelationId"
        >
          <CouponSearch
            :rule-form="ruleForm"
            :disabled="type === 'show'"
          />
        </el-form-item>

        <el-form-item
          label="退单校验："
          required
        >
          <el-radio-group
            v-model="ruleForm.validRefund"
            :disabled="type === 'show'"
          >
            <el-radio :label="1">
              已核销不可退，未核销可退、券自动失效
            </el-radio>
            <el-radio :label="0">
              不校验
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <el-button
            v-if="type !== 'show'"
            type="primary"
            size="small"
            :loading="loading"
            @click="onSubmit"
          >
            保 存
          </el-button>
          <el-button
            size="small"
            @click="onBack"
          >
            取 消
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import SelectGoodsSku from '@/components/common/selectGoodsSku/index';
import CouponSearch from './components/CouponSearch';
export default {
  name: '',
  components: {
    SelectGoodsSku,
    CouponSearch
  },
  props: {},
  data() {
    return {
      type: '',
      loading: false,
      ruleForm: {
        returnCouponName: '',
        activeTimeList: [],
        joinType: 1, // 购买指定商品
        returnCouponRelationType: this.$route.query.type === 'add' ? 2 : null, // 奖品类型 1：优惠券包 2：券
        returnCouponRelationId: null, // 优惠券id
        returnCouponRelationName: '', // 优惠券名称
        returnCouponPackageId: null, // 券包id
        returnCouponPackageName: null, // 券包名称
        validRefund: 1, // 退单校验 0 不校验 ,1校验（已核销不可退、未核销可退、券自动失效）
        goodsList: []
      },
      rules: {
        returnCouponName: [
          { required: true, message: '请输入活动名称', trigger: 'change' }
        ],
        activeTimeList: [
          { required: true, message: '请选择活动时间', trigger: 'change' }
        ],
        returnCouponRelationId: [
          { required: true, message: '请完善优惠券id', trigger: 'change' }
        ]
      }
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.type = this.$route.query.type;
    let id = this.$route.query.id;
    if (id) {
      this.getData(id)
    }
  },
  methods: {
    getData(id) {
      this.$axios.get(this.$api.returnCoupon.detail, {
        params: {
          returnCouponId: id
        }
      }).then(res => {
        this.loading = false
        if (res.code === 0) {
          let obj = res.data;
          this.$set(obj, 'activeTimeList', [obj.startTime, obj.endTime]);
          this.$set(obj, 'joinType', 1);
          this.ruleForm = obj;
        }
      })
    },
    onSubmit() {
      console.log(this.ruleForm)
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let nowTime = (new Date()).valueOf();
          if (this.ruleForm.activeTimeList[0] === this.ruleForm.activeTimeList[1]) {
            this.$message.warning('开始时间不能等于结束时间')
            return
          }
          if (this.ruleForm.activeTimeList[1] < nowTime) {
            this.$message.warning('结束时间不能早于当前时间')
            return
          }
          if (this.ruleForm.goodsList.length === 0) {
            this.$message.warning('请添加商品');
            return
          }
          this.$confirm('是否确认保存?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.loading = true;
            let api = this.type === 'add' ? this.$api.returnCoupon.insert : this.$api.returnCoupon.update
            let params = {
              ...this.ruleForm,
              startTime: this.ruleForm.activeTimeList[0],
              endTime: this.ruleForm.activeTimeList[1]
            }
            console.log(params)
            this.$axios.post(api, params).then(res => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success('操作成功！');
                this.$router.back(-1);
              }
            })
          }).catch(() => {
          });
        }
      })
    },
    onBack() {
      this.$confirm('是否确认返回?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.back(-1);
      }).catch(() => {
      });
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  font-size: 20px;

}
</style>
