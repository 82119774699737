var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "outer" },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-ruleForm",
            attrs: {
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "110px",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "活动名称：", prop: "returnCouponName" } },
              [
                _c("el-input", {
                  staticStyle: { width: "400px" },
                  attrs: {
                    size: "small",
                    maxlength: "30",
                    "show-word-limit": "",
                    placeholder: "请输入活动名称",
                    disabled: _vm.type === "show",
                  },
                  model: {
                    value: _vm.ruleForm.returnCouponName,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "returnCouponName", $$v)
                    },
                    expression: "ruleForm.returnCouponName",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "活动时间：", prop: "activeTimeList" } },
              [
                _c("el-date-picker", {
                  staticStyle: { width: "400px" },
                  attrs: {
                    type: "datetimerange",
                    "range-separator": "至",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                    size: "small",
                    "value-format": "timestamp",
                    disabled: _vm.type === "show",
                  },
                  model: {
                    value: _vm.ruleForm.activeTimeList,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "activeTimeList", $$v)
                    },
                    expression: "ruleForm.activeTimeList",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "参与条件：", required: "" } },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { disabled: _vm.type === "show" },
                    model: {
                      value: _vm.ruleForm.joinType,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "joinType", $$v)
                      },
                      expression: "ruleForm.joinType",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 1 } }, [
                      _vm._v(" 购买指定商品 "),
                    ]),
                  ],
                  1
                ),
                _c("SelectGoodsSku", {
                  attrs: {
                    list: _vm.ruleForm.goodsList,
                    disabled: _vm.type === "show",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "返券：", prop: "returnCouponRelationId" } },
              [
                _c("CouponSearch", {
                  attrs: {
                    "rule-form": _vm.ruleForm,
                    disabled: _vm.type === "show",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "退单校验：", required: "" } },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { disabled: _vm.type === "show" },
                    model: {
                      value: _vm.ruleForm.validRefund,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "validRefund", $$v)
                      },
                      expression: "ruleForm.validRefund",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 1 } }, [
                      _vm._v(" 已核销不可退，未核销可退、券自动失效 "),
                    ]),
                    _c("el-radio", { attrs: { label: 0 } }, [
                      _vm._v(" 不校验 "),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _vm.type !== "show"
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "small",
                          loading: _vm.loading,
                        },
                        on: { click: _vm.onSubmit },
                      },
                      [_vm._v(" 保 存 ")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  { attrs: { size: "small" }, on: { click: _vm.onBack } },
                  [_vm._v(" 取 消 ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }